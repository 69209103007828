import React, { PureComponent } from 'react'
import _ from 'lodash'
import moment from 'moment'

import styles from './Message.module.scss'
import iconQuotesRed from '../../assets/images/icon-quotes-red.svg'

interface IProps {
  message: string
  author: string
  date: Date
}

interface IState {
  translateY: number
  scaleContainer: number
  scaleQuoteImage: number
  scaleAuthorText: number
  scaleDetailsBody: number
  translateDetails: number
  opacityHint: number
}

class Message extends PureComponent<IProps, IState> {
  footerElement: HTMLElement | null = null
  state: IState = {
    translateY: 0,
    scaleContainer: 1,
    scaleQuoteImage: 1,
    scaleAuthorText: 1,
    scaleDetailsBody: 1,
    translateDetails: 0,
    opacityHint: 1,
  }

  handleScroll = () => {
    const scrollY = window.scrollY
    if (window.matchMedia('(min-width: 1440px)').matches) {
      if (this.footerElement) {
        // Calculate footer position and breakpoint value
        const footerBounds = this.footerElement.getBoundingClientRect()
        const padding = 200
        const breakpoint =
          scrollY + footerBounds.top - footerBounds.height - padding

        if (scrollY >= breakpoint) {
          const offset = breakpoint - scrollY
          window.requestAnimationFrame(() =>
            this.setState({ translateY: offset })
          )
        } else {
          window.requestAnimationFrame(() => this.setState({ translateY: 0 }))
        }
      }

      const width = window.innerWidth * 0.3
      const translateWidth = width * 0.45

      const scaleContainer = _.clamp(1 - (scrollY / 300) * 0.45, 0.55, 1)
      const scaleQuoteImage = _.clamp(1 + (scrollY / 300) * 0.82, 1, 1.82)
      const scaleAuthorText = _.clamp(1 + (scrollY / 300) * 0.59, 1, 1.59)
      const scaleDetailsBody = _.clamp(1 - (scrollY / 300) * 0.125, 0.875, 1)
      const translateDetails = _.clamp(
        (scrollY / 300) * translateWidth,
        0,
        translateWidth
      )
      const opacityHint = 1 - _.clamp(scrollY / 150, 0, 1)

      window.requestAnimationFrame(() =>
        this.setState({
          scaleContainer,
          scaleQuoteImage,
          scaleAuthorText,
          scaleDetailsBody,
          translateDetails,
          opacityHint,
        })
      )
    } else {
      window.requestAnimationFrame(() =>
        this.setState({
          translateY: 0,
          scaleContainer: 1,
          scaleQuoteImage: 1,
          scaleAuthorText: 1,
          scaleDetailsBody: 1,
          translateDetails: 0,
          opacityHint: 1,
        })
      )
    }
  }

  componentDidMount() {
    this.footerElement = document.getElementById('footer')
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }

  render() {
    const { message, author, date } = this.props
    const {
      translateY,
      scaleContainer,
      scaleQuoteImage,
      scaleAuthorText,
      scaleDetailsBody,
      translateDetails,
      opacityHint,
    } = this.state
    return (
      <div className={styles.container}>
        <div
          className={styles.content}
          style={{
            transform: `translateY(${translateY}px)`,
          }}
        >
          <div
            className={styles.messageContainer}
            style={{
              transform: `scale(${scaleContainer})`,
            }}
          >
            <img
              className={styles.quotes}
              src={iconQuotesRed}
              alt="Quotes"
              style={{
                transform: `scale(${scaleQuoteImage})`,
              }}
            />
            <div className={styles.message}>
              {message
                ? message
                : author
                ? `${author} shared a list with you!`
                : ''}
            </div>
            <div
              className={styles.author}
              style={{
                transform: `scale(${scaleAuthorText})`,
              }}
            >
              {message ? `- ${author}` : 'Shared via the Dish app'}
            </div>
          </div>
          <div
            className={styles.details}
            style={{ transform: `translateX(${translateDetails}px)` }}
          >
            <div className={styles.shared}>
              <div className={styles.detailsHeader}>Shared by:</div>
              <div
                className={styles.detailsBody}
                style={{
                  transform: `scale(${scaleDetailsBody})`,
                  transformOrigin: 'top left',
                }}
              >
                {author}
              </div>
            </div>
            <div>
              <div className={styles.detailsHeader}>Date:</div>
              <div
                className={styles.detailsBody}
                style={{
                  transform: `scale(${scaleDetailsBody})`,
                  transformOrigin: 'top left',
                }}
              >
                {moment(date).format('MMMM D, YYYY')}
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.scrollHint}
          style={{
            opacity: opacityHint,
            transform: `translateX(${translateDetails}px)`,
          }}
        >
          <div className={styles.scrollHintText}>Scroll</div>
          <div className={styles.scrollHintLine} />
        </div>
      </div>
    )
  }
}

export default Message
