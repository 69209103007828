import React, { PureComponent } from 'react'

import styles from './ListItem.module.scss'
import { IRestaurant } from '../../interfaces'
import utils from '../../utils/utils'
import iconNotesGray from '../../assets/images/icon-notes-gray.svg'

interface IProps {
  restaurant: IRestaurant
  index: number
  hoveredIndex: number | null
  isTop20: boolean
  notesIncluded: boolean
  onItemClick: (restaurant: IRestaurant) => void
  onMouseOver?: (index: number) => void
  onMouseLeave?: (index: number) => void
}

class ListItem extends PureComponent<IProps> {
  handleItemClick = () => {
    const { restaurant, onItemClick } = this.props
    onItemClick(restaurant)
  }

  onMouseOver = () => {
    const { index, onMouseOver } = this.props
    if (onMouseOver) {
      onMouseOver(index)
    }
  }

  onMouseLeave = () => {
    const { index, onMouseLeave } = this.props
    if (onMouseLeave) {
      onMouseLeave(index)
    }
  }

  render() {
    const {
      restaurant,
      index,
      isTop20,
      notesIncluded,
      hoveredIndex,
    } = this.props
    const hideBorder = hoveredIndex === index + 1 || hoveredIndex === index
    return (
      <div
        className={styles.container}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        <div
          className={styles.listItem}
          onClick={this.handleItemClick}
          style={hideBorder ? { borderBottomColor: 'transparent' } : undefined}
        >
          <div className={styles.listItemContent}>
            <div className={styles.listItemTopRow}>
              {isTop20 ? (
                <div className={styles.listItemNumber}>{index + 1}.</div>
              ) : null}
              <div className={styles.listItemTitle}>{restaurant.name}</div>
            </div>
            <div className={styles.listItemBottomRow}>
              {isTop20 ? <div className={styles.listItemNumber} /> : null}
              <div className={styles.listItemSubtitle}>
                {utils.formatShortAddress(restaurant.addressComponents) ||
                  restaurant.address}
              </div>
            </div>
          </div>
          {notesIncluded && restaurant.notes ? (
            <img src={iconNotesGray} />
          ) : null}
        </div>
      </div>
    )
  }
}

export default ListItem
