const isProd =
  process.env.NODE_ENV === 'production' &&
  window.location.hostname === 'share.dishapp.io'

const baseUrl = 'https://dishapp.io/'
const googleApiBaseUrl = 'https://maps.googleapis.com/maps/api/place/'
const devSharedListUrl =
  'https://us-central1-dish-development.cloudfunctions.net/getSharedList'
const prodSharedListUrl =
  'https://us-central1-dish-app-218922.cloudfunctions.net/getSharedList'

const urls = {
  baseUrl,
  termsUrl: baseUrl + 'terms',
  privacyUrl: baseUrl + 'privacy',
  googlePlaceDetails: googleApiBaseUrl + 'details/json?',
  googlePlacePhotos: googleApiBaseUrl + 'photo?',
  sharedListUrl: isProd ? prodSharedListUrl : devSharedListUrl,
}

export default urls
