import _ from 'lodash'
import moment from 'moment'
import { IGeocoderAddressComponent } from '../interfaces'

class Utils {
  orderObjects<T>(objects: T[], order: string[], key: string) {
    return _.compact(order.map(orderKey => _.find(objects, [key, orderKey])))
  }

  // Returns only city and state/province
  formatShortAddress(addressComponents?: IGeocoderAddressComponent[]) {
    if (!addressComponents) {
      return ''
    }

    const shortAddress: string[] = []

    const city = _.find(addressComponents, { types: ['locality', 'political'] })
    shortAddress.push(city ? city.long_name : '')
    const province = _.find(addressComponents, {
      types: ['administrative_area_level_1', 'political'],
    })
    shortAddress.push(province ? province.short_name : '')

    return _.compact(shortAddress).join(', ')
  }

  // Returns only address, city and state/province
  formatMediumAddress(addressComponents?: IGeocoderAddressComponent[]) {
    if (!addressComponents) {
      return ''
    }
    const streetAddress: string[] = []
    const mediumAddress: string[] = []

    const streetNumber = _.find(addressComponents, { types: ['street_number'] })
    streetAddress.push(streetNumber ? streetNumber.long_name : '')
    const street = _.find(addressComponents, { types: ['route'] })
    streetAddress.push(street ? street.long_name : '')

    mediumAddress.push(_.compact(streetAddress).join(' '))
    const city = _.find(addressComponents, { types: ['locality', 'political'] })
    mediumAddress.push(city ? city.long_name : '')
    const province = _.find(addressComponents, {
      types: ['administrative_area_level_1', 'political'],
    })
    mediumAddress.push(province ? province.short_name : '')

    return _.compact(mediumAddress).join(', ')
  }

  getTodaysHours(openingHours: google.maps.places.OpeningHours) {
    if (openingHours) {
      if (
        openingHours.periods.length === 1 &&
        openingHours.periods[0].open.time === '0000' &&
        !openingHours.periods[0].close
      ) {
        return 'Open 24 Hours'
      }
      const today = moment().day()
      const todayHours: Array<{
        close: { day: number; time: string }
        open: { day: number; time: string }
      }> =
        openingHours &&
        openingHours.periods.reduce((result: any, period: any) => {
          if (period.open.day === today) {
            result.push(period)
          }
          return result
        }, [])
      if (todayHours && todayHours.length > 0) {
        return this.formatHoursRange(todayHours[0])
      } else {
        return 'CLOSED'
      }
    }
  }

  formatMilitaryTime(time: string) {
    return moment(time, 'HHmm').format('h:mma')
  }

  formatHoursRange(hours: {
    close: { day: number; time: string }
    open: { day: number; time: string }
  }) {
    if (hours.open === null || hours.close === null) {
      return 'Closed'
    }
    return `${this.formatMilitaryTime(
      hours.open.time
    )} - ${this.formatMilitaryTime(hours.close.time)}`
  }

  formatDayOfWeek = (day: number) => moment(day, 'e').format('dddd')
}

export default new Utils()
