import React, { PureComponent } from 'react'

import styles from './Footer.module.scss'

interface IProps {
  author: string
}

class Footer extends PureComponent<IProps> {
  render() {
    const { author } = this.props
    return (
      <footer id="footer" className={styles.container}>
        <div className={styles.topContent}>
          <div className={styles.topContentText}>
            <div className={styles.topPrimaryText}>
              {`Like food as much as ${author} does?`}
            </div>
            <div className={styles.topSecondaryText}>
              Download the Dish app to organize and share restaurants.
            </div>
          </div>
          <div className={styles.imageContainer}>
            <a
              className={styles.appstoreBadge}
              href="https://itunes.apple.com/us/app/dish-your-restaurant-roadmap/id1414499443?mt=8"
              aria-hidden={true}
            />
            <a href="https://play.google.com/store/apps/details?id=com.dishapp.dish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                className={styles.googleplayBadge}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
              />
            </a>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <div className={styles.bottomPrimaryText}>
            {'Learn more about Dish at '}
            <a className={styles.link} href="https://dishapp.io">
              dishapp.io
            </a>
          </div>
          <div className={styles.bottomSecondaryText}>
            © 2019 Dish Technologies LLC, All Rights Reserved.
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
