import React, { PureComponent, ErrorInfo } from 'react'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import axiosRetry, { exponentialDelay } from 'axios-retry'
import _ from 'lodash'

import './App.scss'
import Home from './screens/Home'
import urls from './const/urls'
import { IUser, ISharedList, IRestaurant } from './interfaces'

axiosRetry(axios, { retries: 5, retryDelay: exponentialDelay })

const emptyAuthor: IUser = {
  id: '',
  firstName: '',
  lastName: '',
}

const emptyList: ISharedList = {
  id: '',
  userId: '',
  message: '',
  title: '',
  restaurants: [],
  notesIncluded: false,
  isTop20: false,
  lastUpdated: new Date(),
}

interface IState {
  author: IUser
  list: ISharedList
  restaurants: IRestaurant[]
}

const id: string | undefined = window.location.pathname.split('/').pop()

class App extends PureComponent<{}, IState> {
  state: IState = {
    author: emptyAuthor,
    list: emptyList,
    restaurants: [],
  }

  fetchData = () => {
    axios
      .get(urls.sharedListUrl, {
        params: {
          id,
        },
      })
      .then(result => {
        const data = result.data
        this.setState({
          author: data.author || emptyAuthor,
          list: data.list || emptyList,
          restaurants: data.restaurants || [],
        })
        if (data.list) {
          document.title = `${data.list.title} | Dish`
        }
      })
  }

  handleVisibilityChange = (ev: Event) => {
    if (!document.hidden) {
      this.fetchData()
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  componentDidMount() {
    if (id && id.length > 0) {
      this.fetchData()
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
    } else {
      window.location.replace(urls.baseUrl)
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  }

  render() {
    return <Home {...this.state} />
  }
}

export default App
