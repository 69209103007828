import React, { PureComponent } from 'react'

import styles from './Header.module.scss'
import logo from '../../assets/images/logo.svg'
import urls from '../../const/urls'

class Header extends PureComponent {
  render() {
    return (
      <header className={styles.container}>
        <a className={styles.linkContainer} href={urls.baseUrl}>
          <img className={styles.logo} src={logo} alt="Dish Logo" />
        </a>
      </header>
    )
  }
}

export default Header
