import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: 'https://7d1bfc9e792041ad8d9eb10c1d085f09@sentry.io/1462458',
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
