import React, { PureComponent } from 'react'
import _ from 'lodash'

import styles from './Home.module.scss'
import Message from '../../components/Message'
import List from '../../components/List'
import { ISharedList, IRestaurant, IUser } from '../../interfaces'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import utils from '../../utils/utils'

interface IProps {
  author: IUser
  list: ISharedList
  restaurants: IRestaurant[]
}

class Home extends PureComponent<IProps> {
  render() {
    const { author, list, restaurants } = this.props
    return (
      <div>
        <div className={styles.container}>
          <Header />
          <div className={styles.content}>
            <Message
              message={list.message}
              author={author.id ? `${author.firstName} ${author.lastName}` : ''}
              date={list.lastUpdated}
            />
            <List
              author={author.firstName}
              list={list}
              restaurants={utils.orderObjects(
                restaurants,
                list.restaurants,
                'id'
              )}
            />
          </div>
        </div>
        <Footer author={author.firstName} />
      </div>
    )
  }
}

export default Home
