/*global google*/
import React, { PureComponent } from 'react'
import Hammer from 'react-hammerjs'

import styles from './DetailsBar.module.scss'
import { IRestaurant } from '../../interfaces'
import utils from '../../utils/utils'
import iconPhoneBlack from '../../assets/images/icon-phone-black.svg'
import iconWebBlack from '../../assets/images/icon-web-black.svg'
import iconMapBlack from '../../assets/images/icon-map-black.svg'

interface IProps {
  restaurant: IRestaurant | null
  notesIncluded: boolean
  author: string
  onClose(): void
}

interface IState {
  placeDetails: google.maps.places.PlaceResult | null
}

class DetailsBar extends PureComponent<IProps, IState> {
  state: IState = {
    placeDetails: null,
  }

  getPlaceDetails(placeId: string) {
    const googleAttrDiv = document.getElementById('google')
    const service = new google.maps.places.PlacesService(
      googleAttrDiv as HTMLDivElement
    )
    service.getDetails(
      {
        placeId,
        fields: [
          'place_id',
          'opening_hours',
          'photos',
          'formatted_phone_number',
          'website',
        ],
      },
      (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.setState({
            placeDetails: result,
          })
        } else {
          this.setState({ placeDetails: null })
        }
      }
    )
  }

  handleSwipe = () => {
    this.props.onClose()
  }

  componentDidMount() {
    const { restaurant } = this.props
    if (restaurant) {
      this.getPlaceDetails(restaurant.id)
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { restaurant } = this.props
    if (prevProps.restaurant !== restaurant && restaurant) {
      this.setState({ placeDetails: null })
      this.getPlaceDetails(restaurant.id)
    }
  }

  render() {
    const { author, restaurant, notesIncluded } = this.props
    const { placeDetails } = this.state

    const todaysHours =
      placeDetails && placeDetails.opening_hours
        ? utils.getTodaysHours(placeDetails.opening_hours)
        : ''
    const imageUrl =
      placeDetails && placeDetails.photos && placeDetails.photos.length > 0
        ? placeDetails.photos[0].getUrl({ maxHeight: 300, maxWidth: 300 })
        : ''
    const phoneNumber = placeDetails
      ? placeDetails.formatted_phone_number || ''
      : ''
    const website = placeDetails ? placeDetails.website || '' : ''
    const directions = restaurant
      ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          restaurant.name
        )}&query_place_id=${encodeURIComponent(restaurant.id)}`
      : ''

    return (
      <>
        <div
          className={styles.overylay}
          style={restaurant ? { opacity: 0.1 } : { opacity: 0 }}
        />
        <Hammer direction="DIRECTION_DOWN" onSwipe={this.handleSwipe}>
          <div
            id="details"
            className={
              restaurant ? styles.container : styles.containerCollapsed
            }
          >
            <div className={styles.tab} />
            <div className={styles.content}>
              <div className={styles.titleContainer}>
                {imageUrl ? (
                  <img
                    className={styles.image}
                    src={imageUrl}
                    alt="Restaurant Preview"
                  />
                ) : (
                  <div className={styles.image} />
                )}
                <div className={styles.title}>
                  {restaurant ? restaurant.name : ''}
                </div>
              </div>
              <div className={styles.infoContainer}>
                <div className={styles.hoursAddress}>
                  <div className={styles.hoursContainer}>
                    <span className={styles.hoursTitle}>Today's Hours:</span>
                    <span className={styles.hours}>{todaysHours}</span>
                  </div>
                  <div className={styles.address}>
                    {restaurant
                      ? utils.formatMediumAddress(
                          restaurant.addressComponents
                        ) || restaurant.address
                      : ''}
                  </div>
                  <a className={styles.phoneNumber} href={`tel:${phoneNumber}`}>
                    {phoneNumber}
                  </a>
                </div>
                <div className={styles.actionsContainer}>
                  {phoneNumber ? (
                    <a
                      href={`tel:${phoneNumber}`}
                      className={styles.actionPhone}
                    >
                      <img
                        className={styles.action}
                        src={iconPhoneBlack}
                        alt="Phone Number"
                      />
                    </a>
                  ) : (
                    <div className={styles.actionPhone}>
                      <img
                        className={styles.action}
                        style={{ opacity: 0.5 }}
                        src={iconPhoneBlack}
                        alt="Phone Number"
                      />
                    </div>
                  )}
                  {directions ? (
                    <a className={styles.actionLink} href={directions}>
                      <img
                        className={styles.action}
                        src={iconMapBlack}
                        alt="Directions"
                      />
                    </a>
                  ) : (
                    <div>
                      <img
                        className={styles.action}
                        style={{ opacity: 0.5 }}
                        src={iconMapBlack}
                        alt="Directions"
                      />
                    </div>
                  )}
                  {website ? (
                    <a className={styles.actionLink} href={website}>
                      <img
                        className={styles.action}
                        src={iconWebBlack}
                        alt="Website"
                      />
                    </a>
                  ) : (
                    <div>
                      <img
                        className={styles.action}
                        style={{ opacity: 0.5 }}
                        src={iconWebBlack}
                        alt="Website"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {restaurant && restaurant.notes && notesIncluded ? (
              <div className={styles.notesContainer}>
                <div className={styles.notesTitle}>{`${author}'s Notes:`}</div>
                <div className={styles.notes}>{`"${restaurant.notes}"`}</div>
              </div>
            ) : null}
          </div>
        </Hammer>
      </>
    )
  }
}

export default DetailsBar
