import React, { PureComponent } from 'react'
import _ from 'lodash'

import styles from './List.module.scss'
import iconIPhone from '../../assets/images/image-iphone-transparent.png'
import { IRestaurant, ISharedList } from '../../interfaces'
import ListItem from '../ListItem'
import DetailsBar from '../DetailsBar'

interface IProps {
  list: ISharedList
  author: string
  restaurants: IRestaurant[]
}

interface IState {
  badgeOpacity: number
  iphoneOpacity: number
  iphoneScale: number
  listScale: number
  listTranslate: number
  selectedRestaurant: IRestaurant | null
  hoveredIndex: number | null
}

class List extends PureComponent<IProps, IState> {
  state: IState = {
    badgeOpacity: 1,
    iphoneOpacity: 1,
    iphoneScale: 1,
    listTranslate: 0,
    listScale: window.matchMedia('(min-width: 768px)').matches ? 0.92 : 0.7,
    selectedRestaurant: null,
    hoveredIndex: null,
  }
  listElement: HTMLElement | null = null
  listRef: React.RefObject<HTMLDivElement> = React.createRef()
  detailsBarRef: React.RefObject<HTMLDivElement> = React.createRef()

  handleScroll = () => {
    const scrollY = window.scrollY

    if (window.matchMedia('(min-width: 1440px)').matches) {
      const badgeOpacity = 1 - _.clamp(scrollY / 300, 0, 1)
      const iphoneScale = _.clamp(1 + (scrollY / 300) * 0.08, 1, 1.08)
      const iphoneOpacity = 1 - _.clamp(scrollY / 300, 0, 1)
      const listScale = _.clamp(0.92 + (scrollY / 300) * 0.08, 0.92, 1)
      const listTranslate = _.clamp((scrollY / 300) * -64, -64, 0)
      window.requestAnimationFrame(() =>
        this.setState({
          badgeOpacity,
          iphoneOpacity,
          iphoneScale,
          listScale,
          listTranslate,
        })
      )
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      const badgeOpacity = 1 - _.clamp(scrollY / 300, 0, 1)
      const iphoneScale = _.clamp(1 + (scrollY / 300) * 0.08, 1, 1.08)
      const iphoneOpacity = 1 - _.clamp(scrollY / 300, 0, 1)
      const listScale = _.clamp(0.92 + (scrollY / 300) * 0.08, 0.92, 1)
      const listTranslate = _.clamp((scrollY / 300) * -8, -8, 0)
      window.requestAnimationFrame(() =>
        this.setState({
          badgeOpacity,
          iphoneOpacity,
          iphoneScale,
          listScale,
          listTranslate,
        })
      )
    } else {
      const screenHeight = window.innerHeight
      const top = screenHeight - 300

      const iphoneOpacity = 1 - _.clamp(scrollY / top, 0, 1)
      const iphoneScale = _.clamp(1 + (scrollY / top) * 0.4, 1, 1.4)
      const listScale = _.clamp(0.7 + (scrollY / top) * 0.3, 0.7, 1)
      window.requestAnimationFrame(() =>
        this.setState({
          badgeOpacity: 0,
          iphoneOpacity,
          iphoneScale,
          listScale,
          listTranslate: 0,
        })
      )
    }
  }

  handleClickOutside = (evt: Event) => {
    const details = document.getElementById('details')
    if (
      this.listRef.current &&
      details &&
      evt.target &&
      !this.listRef.current.contains(evt.target as Node) &&
      !details.contains(evt.target as Node)
    ) {
      this.setState({ selectedRestaurant: null })
    }
  }

  handleItemClick = (item: IRestaurant) => {
    this.setState({ selectedRestaurant: item })
  }

  handleDetailsClose = () => {
    this.setState({ selectedRestaurant: null })
  }

  handleMouseOver = (index: number) => {
    this.setState({ hoveredIndex: index })
  }

  handleMouseLeave = (index: number) => {
    const { hoveredIndex } = this.state
    if (hoveredIndex === index) {
      this.setState({ hoveredIndex: null })
    }
  }

  componentDidMount() {
    this.listElement = document.getElementById('list')
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const { author, list, restaurants } = this.props
    const {
      badgeOpacity,
      iphoneOpacity,
      iphoneScale,
      listScale,
      listTranslate,
      selectedRestaurant,
      hoveredIndex,
    } = this.state
    return (
      <div id="list" className={styles.container}>
        <div
          className={styles.listContainer}
          style={{
            transform: `translateX(${listTranslate}px)`,
          }}
        >
          <img
            className={styles.image}
            style={{
              opacity: iphoneOpacity,
              transform: `scale(${iphoneScale}) translateX(-4px)`,
            }}
            src={iconIPhone}
            alt="iPhone"
          />
          <div
            ref={this.listRef}
            className={styles.list}
            style={{
              transform: `scale(${listScale})`,
            }}
          >
            <div className={styles.listTitle}>{list.title}</div>
            {restaurants.map((rest, index) => (
              <ListItem
                key={rest.id}
                index={index}
                isTop20={list.isTop20}
                notesIncluded={list.notesIncluded}
                restaurant={rest}
                onItemClick={this.handleItemClick}
                hoveredIndex={hoveredIndex}
                onMouseOver={this.handleMouseOver}
                onMouseLeave={this.handleMouseLeave}
              />
            ))}
          </div>
        </div>
        <a
          className={styles.appstoreBadge}
          style={{ opacity: badgeOpacity }}
          href="https://itunes.apple.com/us/app/dish-your-restaurant-roadmap/id1414499443?mt=8"
          aria-hidden={true}
        />
        <a
          id="google"
          style={{ opacity: badgeOpacity }}
          href="https://play.google.com/store/apps/details?id=com.dishapp.dish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            className={styles.googleplayBadge}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
          />
        </a>
        <DetailsBar
          restaurant={selectedRestaurant}
          author={author}
          notesIncluded={list.notesIncluded}
          onClose={this.handleDetailsClose}
        />
      </div>
    )
  }
}

export default List
